<template>
  <div>
  </div>
</template>

<script>
import {requestWXUserInfo} from '../api/keneng'
import {Loading} from 'element-ui'
import {getOauthUrl, setOauthUrl, setOpenId, setUnionId} from "../utils/store";

export default {
  data() {
    return {
      code: this.$route.query.code,
      redirect_url: this.$route.query.next_route,
      loadingInstance: undefined
    }
  },
  created() {
    if (this.code) {

      let oldUrl = getOauthUrl()
      if (oldUrl && oldUrl != '') {
        //do nothing
      } else {
        let link = window.location.href
        setOauthUrl(link)
      }

      this.loadingInstance = Loading.service({fullscreen: true, spinner: 'el-icon-loading'})
      requestWXUserInfo(this.code).then(res => {
        this.loadingInstance.close()
        if (res.data.code === 0) {
          var result = res.data.data
          if (result.unionid) {
            var unionid = result.unionid
            let open_id = result.openid
            setOpenId(open_id)
            setUnionId(unionid)
            this.$router.replace({
              path: this.redirect_url,
              query: {
                open_id: open_id
              }
            })
          } else {
            this.$message.error('发现你还没有关注我们的公众号，请先关注公众号！')
          }
        } else {
          this.$message.error('获取用户信息出错')
        }
      })
    } else {
      this.$message.error('未获取到code授权')
    }
  },
  methods: {}
}
</script>

<style>

</style>
